<template>
  <div style="margin:0">
    <div class="modalityTop">
      <el-form
        ref="search"
        class="searchForm"
        :model="paramsData"
        label-width="auto"
      >
        <el-form-item label="时间段" prop="equipmentStatus" label-width="55px">
          <el-date-picker
            
            size="mini"
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
            <!-- @change="search" -->
          </el-date-picker>
        </el-form-item>
      
        <el-form-item label="巡线员" prop="equipmentStatus" >
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.userId"
            placeholder="请选择巡线员"
            
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.username"
              :value="item.id">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务状态" prop="equipmentStatus" style="margin-left: 14px;">
          <el-select
            clearable
            size="mini"
            style="width:170px"
            v-model="paramsData.taskGenerateStatus"
            placeholder="请选择状态"
            
          >
          <!-- @change="search" -->
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" prop="equipmentStatus">
          <el-input
          clearable
          size="mini"
            placeholder="请输入任务编号"
            v-model="paramsData.searchText"
            style="width:250px"
          >
          </el-input>
        </el-form-item>
       <el-form-item label="" prop="" label-width="0">
        <el-button
          size="mini"
          type="primary"
          @click="search"
          style="margin-left:10px;"
          >查询</el-button
        >
        <el-button
          size="mini"
          type="warning"
          style="margin-left: 10px;"
          @click="resize"
          >重置</el-button
        >
         </el-form-item>
      </el-form>
    </div>
    <el-divider style="color: #f2f2f2"></el-divider>
    <div class="modalityCenter">
     <div class="tableTool">
        <el-button
          size="mini"
          type="primary"
          class="addButton"
          v-if="$anthButtons.getButtonAuth('addLinetaskInstancy')"
          @click="addItem()"
          >添加</el-button>
      </div>
    <el-table
     border
      :data="tableData"
      style="width: 100%"
      height="calc(100vh - 394px)"
      :row-class-name="tableRowClassName"
      :header-cell-style="{
        color: '#333',
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        fontWeight: 900,
        textAlign:'center',
        background: '#f8f8f9',
      }"
    >
     <el-table-column type="index" align="left" label="序号" width="80"></el-table-column>
      <el-table-column property="taskCode" label="任务编号" align="left" show-overflow-tooltip width="180">
        <template slot-scope="{row}">
          <div :style="{color:row.isRead==2?'red':'#0C235B'}">{{row.taskCode}}</div>
        </template>
      </el-table-column>
      <el-table-column
        property="checkingPoint"
        label="巡检点数"
        align="left"
        width="100"
      >
      <template slot-scope="{ row }">
            <div style="color: #1082ff; cursor: pointer"  @click="SeetracFun(row)">{{row.checkingPoint}}</div>
      </template>
      </el-table-column>
      <el-table-column
        property="taskFrequency"
        label="巡检周期"
        align="left"
      >
      <template slot-scope="{ row }">
            {{row.dayNumber || 1}}天{{ row.frequency }}次
      </template>
      </el-table-column>
      <el-table-column
        property="punch"
        label="已完成数/应完成数"
        align="left">
        <template slot-scope="{ row }">
          <div v-if="row.finishPush" style="color:#84d7ff;cursor:pointer;" @click="details(row.id)">
           {{row.finishPush.split(',').length|| 0}}/{{ row.pushCount || 0}}
        </div> 
        <div v-else>{{row.finishPush ? row.finishPush.split(',').length : 0}}/{{ row.pushCount || 0}}</div> 
      </template>
      </el-table-column>
      
      
      <el-table-column
        property="executorPeopleName"
        label="巡线员"
        align="left"
        width="180"
      >
       <template slot-scope="{ row }">
          <div v-for="(item,index) in row.executorPeopleName.split(',')" :key="index">{{item}}</div>
        </template>
      </el-table-column>
      
      <el-table-column property="executionTime" label="执行时间" align="left" width="180">
        <template slot-scope="{ row }">
          {{`${String(row.taskDurationStartTime).replaceAll("-", "/")} - ${String(row.taskDurationEndTime).replaceAll("-", "/")}`}}
        </template>
      </el-table-column>
      <el-table-column
        property="taskStatusName"
        label="任务状态"
        width="80"
        align="left">
        <template slot-scope="{ row }">
          <el-tag v-if="row.taskStatusName == '未开始'" size="mini" style="color: #F4C41D;border: 1px solid #F0DCA8;background: #FFFCF6;">
              {{ row.taskStatusName }}
          </el-tag> 
          <!-- 进行中 全部转派 -->
          <el-tag v-else-if="(row.taskStatusName == '进行中' && row.isRedeploy == 3)"  size="mini" stype='success'>
              已完成
          </el-tag> 
          <el-tag v-else :type='taskStatus(row.taskStatusName)' size="mini">
              {{ row.taskStatusName }}
          </el-tag>  
        </template>
      </el-table-column>
      <el-table-column
        property="taskRemark"
        label="备注"
        width="300"
        align="left">
      </el-table-column>
      <el-table-column label="操作" align="left" width="240">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
             v-if="$anthButtons.getButtonAuth('remarkLinetaskInstancy')"
            @click="IsOpenFun(4, row)"
            >备注</el-button
          >
          <el-button
            type="primary" plain
            @click="closeAll(row)"
            size="mini"
            v-if="row.taskGenerateStatus ==1 && $anthButtons.getButtonAuth('offLinetaskInstancy')"
            >关闭</el-button>
          <el-button
            type="primary" plain
            @click="addItem(row)"
            size="mini"
            v-if="row.taskGenerateStatus == 0 && $anthButtons.getButtonAuth('editLinetaskInstancy')"
            >编辑</el-button>
          <el-button
            type="danger"
            @click="del(row)"
            size="mini"
            v-if="row.taskGenerateStatus == 0 && $anthButtons.getButtonAuth('deleteLinetaskInstancy')"
            >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: left">
      <el-pagination
        @size-change="handleSizeChange"
        style="margin-top: 10px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="paramsData.size"
        :current-page="paramsData.current"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    </div>
   
    <!-- 新增修改 -->
    <el-dialog :title="titles" :visible.sync="addVisible" @close="CloseChangePlan" width="460px" :close-on-click-modal='false'>
      <el-form :model="DeviceData"   ref="DeviceDataAdd" label-width="80px" :rules="rules">
        <el-form-item label="巡检事件" prop="eventName" >
          <el-input
            style="width: 338px"
            placeholder="请选择(可多选)"
            v-model="DeviceData.eventName"
            readonly
            @focus="selectEvent()"
          ></el-input>
        </el-form-item>
       
        <div >
          <div style="overflow:hidden">
            <el-form-item label="执行频率" prop="dayNumber" style="width:200px;float:left">
            <el-input  v-model="DeviceData.dayNumber" style="width:150px" placeholder="输入"  :max="30" min="1"></el-input>
             
          </el-form-item>
          <el-form-item label="天"  style="width:200px;float:left;margin-left:-24px" prop="frequency">
          <el-input  v-model="DeviceData.frequency" type="" style="width:139px"  placeholder="输入" max="30" min="1"></el-input>
             </el-form-item>
          <el-form-item label="次"  style="width:10px;float:left;margin-left:-29px"></el-form-item>
          </div>
          <el-form-item label="巡线员" prop="taskUser">
          <el-select style="width: 338px" v-model="DeviceData.taskUser" multiple>
            <el-option
              v-for="item in excutorDownArray1"
              :key="item.id"
              :label="item.username+'('+item.mobile+')'"
              :value="item.id">
            
            </el-option>
          </el-select>
        </el-form-item>
          <el-form-item label="执行时间" prop="addTimes">
            <el-date-picker
                @change="changePicker"
              value-format="yyyy-MM-dd"
              v-model="DeviceData.addTimes"
              type="daterange"
              style="width: 338px"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions0"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="submitPatrol" type="primary" >确 认</el-button>
        <el-button @click="addVisible = false"  >取 消</el-button>
      </div>
    </el-dialog>
  
    <!-- 备注 -->
    <el-dialog :visible.sync="remarkBool" width="460px" :close-on-click-modal='false'>
       <el-form :model="remarkData"   ref="remarkData" label-width="80px" :rules="rules1">
        <el-form-item label="备注" prop="taskRemark">
          <el-input
            style="width: 100%"
            maxlength="200"
            name="limitLength"
            type="textarea"
            show-word-limit
            placeholder="请输入备注"
            v-model="remarkData.taskRemark"
          ></el-input>
        </el-form-item>
      </el-form>
       <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="submitRemark" type="primary"  >确 认</el-button>
        <el-button size="small" @click="remarkBool = false"  >取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="taskBool">
      <div style="width: 100%; height: 500px" id="taskMap"></div>
    </el-dialog>
    <!-- 巡检事件列表 -->
    <el-dialog  :visible.sync="eventListD"  width="1084px" :show-close="false" :close-on-click-modal='false'>
      <el-form :model="searchEvent"   ref="DeviceData" :inline="true">
        <el-form-item label="" >
          <el-input
          clearable
          size="mini"
            placeholder="请输入地址"
            v-model="searchEvent.searchText"
            style="width:250px">
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="" label-width="0">
        <el-button
          size="mini"
          type="primary"
          @click="getEventList"
          style="margin-left:10px;"
          >搜索</el-button
        >
        <el-button
          size="mini"
          type="warning"
          style="margin-left: 10px;"
          @click="resizeEvent"
          >重置</el-button
        >
         </el-form-item>
      </el-form>
      <el-table border
      :data="tableDataEvents"
      height="450px"
      tooltip-effect="dark"
      ref="multipleTable"
      @selection-change="handleSelectionChangeEvent"
      >
        <el-table-column type="selection" align="center" width="80"></el-table-column>
        <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
        <el-table-column
        property="eventCodeIdConfirmName"
        label="事件类型"
        align="center"
      ></el-table-column>
      <el-table-column
        property="eventAddressConfirm"
        label="地址"
        align="center"
      ></el-table-column>
        <el-table-column
        property="eventDescribe"
        label="说明"
        align="center"
      ></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <div style="display:inline-block;float: left;">
          <el-pagination
          @size-change="handleSizeChangeEvent"
          style="margin-top: 0px"
          background
          layout="total,sizes,prev, pager, next,jumper"
          :total="totalEvents"
          :page-sizes="[10,30, 50, 100]"
          :page-size="searchEvent.size"
          :current-page="searchEvent.current"
          @current-change="handleCurrentChangeEvent">
        </el-pagination>
        </div>
        <div style="display:inline-block">
         <el-button size="small" @click="eventListD = false" >取 消</el-button>
        <el-button size="small" @click="submitSelectEvent" type="primary">确 认</el-button>
        </div>
       
      </div>
    </el-dialog>
    <!-- 巡检事件 -->
    <el-dialog  :visible.sync="pointListD"  width="1084px" :show-close="false" :close-on-click-modal='false'>
      <el-table border
      :data="tableDataEvents"
      @selection-change="handleSelectionChangeEvent"
      >
      
        <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
        <el-table-column
        property="eventCodeIdConfirmName"
        label="事件类型"
        align="center"
      ></el-table-column>
      <el-table-column
        property="eventAddressConfirm"
        label="地址"
        align="center"
      ></el-table-column>
        <el-table-column
        property="eventDescribe"
        label="说明"
        align="center"
      ></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <div style="display:inline-block;float: left;">
          <el-pagination
          @size-change="handleSizeChangeEvent"
          style="margin-top: 0px"
          background
          layout="total,sizes,prev, pager, next,jumper"
          :total="totalEvents"
          :page-sizes="[10,30, 50, 100]"
          :page-size="searchEvent.size"
          :current-page="searchEvent.current"
          @current-change="handleCurrentChangeEvent">
        </el-pagination>
        </div>
        <div style="display:inline-block">
         <el-button size="small" @click="pointListD = false" >取 消</el-button>
        </div>
       
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  updatetask,
  getNetwork,
  getUserList,
  planInfos,
  redeploy,
  taskPageEvent,
  eventPage,
  taskDelete,
  eventIsOpen,
  eventPageNoimg,
  eventRemark
} from "../../RequestPort/maintenance/task";
export default {
  name: "inspeTask",
  data() {
    return {
      pickerOptions0: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7;
          }
        }, 
        // 巡检事件总数
        totalEvents:0,
        // 备注
        remarkData:{
          taskRemark:''
        }, 
        // 备注弹窗
        remarkBool:false,
        // 转派弹窗
        redeployVisible:false,
        //任务状态 0-未开始 1-进行中 2-部分完成3 已完成
        options: [{
          value: '0',
          label: '未开始'
        }, {
          value: '1',
          label: '进行中'
        }, 
         {
          value: '2',
          label: '已完成'
        },{
          value: '3',
          label: '未完成'
        }],
        // 执行频率
         options1: [{
          value: 1,
          label: '1次'
        }, {
           value: 2,
          label: '2次'
        }, {
          value: 3,
          label: '3次'
        }, {
           value: 4,
          label: '4次'
        }, {
          value: 5,
          label: '5次'
        }, {
           value: 6,
          label: '6次'
        }, {
          value: 7,
          label: '7次'
        }, {
           value: 8,
          label: '8次'
        }, {
          value: 9,
          label: '9次'
        }, {
          value: 10,
          label: '10次'
        }
        
        
        
        ],
        // 执行频率
        options2: [
        {
          value: 1,
          label: '1天'
        }, {
          value: 2,
          label: '2天'
        }, {
          value: 3,
          label: '3天'
        },{
          value: 4,
          label: '4天'
        }, {
          value: 5,
          label: '5天'
        }, {
          value: 6,
          label: '6天'
        },{
          value: 7,
          label: '7天'
        }, {
          value: 8,
          label: '8天'
        }, {
          value: 9,
          label: '9天'
        },{
          value: 10,
          label: '10天'
        }, {
          value: 14,
          label: '14天'
        }, {
          value: 30,
          label: '30天'
        }
        
        ],
        // 查询条件
      paramsData: {
        current: 1,
        size: 100,
        taskGenerateStatus:null,
      },
      rules: {
        taskName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
        ],
        planId: [{ required: true, message: "请选择巡检计划", trigger: "change" }],
        taskUser: [{ required: true, message: "请选择巡线员", trigger: "change" }],
        taskExecuteOnceDate: [{ required: true, message: "请选择任务时间", trigger: "change" }],
        taskFrequency: [
          { required: true, message: "请输入每天频率", trigger: "change" }
        ],
        executeDay: [{ required: true, message: "请选择天数", trigger: "change" }],
         taskRemark:[
          { required: true, message: "备注不能为空", trigger: "blur" },
        ],
        eventName:[{ required: true, message: "请选巡检事件", trigger: "change" }],
        dayNumber:[{ required: true, message: "请选巡检天数", trigger: "change" },{pattern:/^\+?[1-9]\d*$/,message: "请输入大于0的正整数"}],
        frequency:[{ required: true, message: "请选巡检频次", trigger: "change" },{pattern:/^\+?[1-9]\d*$/,message: "请输入大于0的正整数"}],
        taskDurationStartTime:[{required: true, message: "请选执行时间", trigger: "change" }],
        addTimes:[{required: true, message: "请选执行时间", trigger: "change" }]
      },
      rules1: {
         taskRemark:[
          { required: true, message: "备注不能为空", trigger: "blur" },
        ],
      },
      // 详情对象
      detiltable: {},
      // 新增任务
      addVisible: false,
      // 详情弹窗
      detilVisible: false,
      // 总条数
      total: 0,
      // 查询暂存的时间段
      DataPicker: [],
      // 任务列表
      tableData: [],
      // 复选暂存
      // multipleSelection: [],
      // 片区字典，巡检区域
      selectDownArray: [],
      // 巡线人员
      excutorDownArray: [],
      excutorDownArray1:[],
      // 巡检计划列表
      patrolArray: [],
      // 新增任务对象
      DeviceData: {
        dayNumber:1,frequency:1,eventName:'',
        addTimes:[],
        taskUser:[]
      },
      // 弹窗
      titles:'',
      // 巡线图片
      taskBool: false,
      // 转派人员下拉
      excutorSpecialArray:[],
      // 巡检事件（列表弹窗）
      eventListD:false,
      tableDataEvents:[],
      tableDataEventCopys:[],
      searchEvent:{
        current: 1,
        size: 100,
      },
      selectList:{},
      addTimes:[],
      pointListD:false,
      // 选中事件
      listEventId:[]
    };
  },
  watch:{
  },
  mounted() {
    // 巡检区域字典，片区下拉
    getNetwork().then((e) => {
      this.selectDownArray = e.data;
    });
    // 获取巡线员
    getUserList({type:2}).then((e) => {
      this.excutorDownArray = e.data;
    });
    // 获取巡线员排除
    getUserList({type:1}).then((e) => {
      this.excutorDownArray1 = e.data;
    });
    // 获取列表
    this.loadlist(this.paramsData);
    /**
     * 巡检计划
     */
    planInfos().then((e) => {
      this.patrolArray = e.data;
    });
    this.getEventList(1)
    // 巡检列表
    this.getEventList1(1)
  },
  methods: {
    resizeEvent(){
      this.searchEvent = {current:1,size: 100,type:1}
      this.getEventList(1)
    },
    closeAll(row){
      this.$confirm(`是否确定关闭?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
      eventIsOpen({id:row.id,taskGenerateStatus:4,shouldPush:row.pushCount}).then(res=>{
        this.$message.error(res.msg)
        this.loadlist(this.paramsData);
      })
      })
    },
    /**
     * 巡检事件（列表弹窗选中事件）
     */
    submitSelectEvent(){
      let listEventName = []; 
      this.listEventId = []
      let num = Math.ceil(this.totalEvents/this.searchEvent.size)
      for(var i=1;i<=num;i++){
        if(this.selectList[i]&&this.selectList[i].length>0){
          this.selectList[i].forEach(element => {
            listEventName.push(element.eventCodeIdConfirmName)
            this.listEventId.push(element.id)
          });
        }
      }
      if(this.listEventId.length<=0){
        this.$message.warning('请至少勾选一条数据')
        return
      }else{
        this.DeviceData.eventName = listEventName.join(',')
        this.DeviceData.eventId = this.listEventId.join(',')
        this.eventListD = false
        // this.$refs.DeviceData.validateField('eventName')
      }
      
    },
    handleSelectionChangeEvent(val){
      this.selectList[this.searchEvent.current] = val;
    },
    /**
     * 编辑添加
     */
    addItem(row){
      this.listEventId = []
      this.getEventList1(1)
      if(row){
        this.DeviceData = {...this.DeviceData,...row};
        this.DeviceData.addTimes = [row.taskDurationStartTime,row.taskDurationEndTime]
        if(this.DeviceData.taskUser && (typeof(this.DeviceData.taskUser) != 'number')){
          this.DeviceData.taskUser = this.DeviceData.taskUser.split(',').map(Number)
        }
        //  else {
        //   this.DeviceData.taskUser = [this.DeviceData.taskUser ]
        // }
        let listEventName = []; 
        for(var i=0;i<this.tableDataEventCopys.length;i++){
          if(row.eventIds){
            row.eventIds.split(',').forEach(element => {
              if(this.tableDataEventCopys[i]&& (this.tableDataEventCopys[i].id == element)){
                listEventName.push(this.tableDataEventCopys[i].eventCodeIdConfirmName)
                this.listEventId.push(element)
              }
            });
          }
        }

        this.DeviceData = {...this.DeviceData, ...{eventName:listEventName.join(',')}}
        this.DeviceData.eventId = this.listEventId.join(',')
      }
      this.addVisible = true
    },
    /**
     * 巡检事件（打开巡检列表）
     */
    selectEvent(){
      
      this.eventListD = true;
      this.searchEvent = {current:1,size: 100,type:1}
      // this.searchEvent.type = 1
      this.getEventList(1)
    },
    /**
     * 跳到事件列表
     */
    details(id){
      // this.$router.push({ path: '/home/eventPolling', query: { id} })
      let chakan = this.$router.resolve({
					path: '/home/eventPollings',      //跳转目标窗口的地址
					query:  { id}
				})
				window.open(chakan.href);
    },
    // 获取3000条默认 添加编辑复选框分页选中不上问题
    getEventList1(page){
      let data = JSON.parse(JSON.stringify(this.searchEvent))
      if(page){
        data.current = 1
      }
      // 超哥确认
      data.size = 3000
      //
      eventPageNoimg(data).then(res=>{
        this.tableDataEventCopys = res.data.records
      })
    },
    getEventList(page){
      if(page){
        this.searchEvent.current = 1
      }
      let that = this
      eventPage(this.searchEvent).then(res=>{
        this.tableDataEvents = res.data.records
        this.totalEvents = res.data.total;
        
        this.listEventId = [...new Set(this.listEventId)]
        if(this.listEventId.length > 0) {
        that.listEventId.forEach(el => {
          that.tableDataEvents.forEach(ele => {
            if(ele.id == el){
              that.$nextTick(() =>{
                 that.$refs.multipleTable.toggleRowSelection(ele);
              })
             
            }
          })
          
        })
      }
      })
    },
    /**
     * 任务状态
     */
    taskStatus(start){
      if(start == '进行中') return 'warning' 
      if(start == '已转派') return '' 
      if(start == '已完成') return 'success' 
      if(start == '未完成') return 'danger' 
    },
    /**
     * 斑马线
     */
    tableRowClassName({row, rowIndex}) {
        if (rowIndex%2==0) {
          return 'warning-row';
        } else {
          return 'success-row';
        }
      },
      /**
       * 选择执行类型
       */
      taskTypeSelect(val){
        if(val == 'one') {
          // 执行频率
          this.DeviceData.taskFrequency = ''
          this.DeviceData.executeDay = ""
          // 时间范围
          this.DeviceData.StartAndEndTime = []
        } else {
          this.DeviceData.taskExecuteOnceDate = ""
        }
      },
      /**
       * 输入时间范围
       */
    changePicker(){
      // this.$forceUpdate()
      if(this.DeviceData.addTimes&&this.DeviceData.addTimes.length>0){
        this.DeviceData.taskDurationStartTime = this.DeviceData.addTimes[0]
        this.DeviceData.taskDurationEndTime = this.DeviceData.addTimes[1]
      }else{
        delete this.DeviceData.taskDurationStartTime;
        delete this.DeviceData.taskDurationEndTime;
      }
    },

    /**
     * 选择时间范围事件
     */
    DatePickerFun(e){
      this.$forceUpdate()
    },
    /**
     * 备注弹窗
     */
    IsOpenFun(e, row) {
      this.remarkBool = true
      if(this.$refs.remarkData){
        this.$refs.remarkData.resetFields()
      }
      this.remarkData.id = row.id
      this.remarkData.taskRemark = row.taskRemark
    },
    /**
     * 备注提交
     */
    submitRemark(){
      this.$refs.remarkData.validate((valid) => {
        if (valid) {
          eventRemark(this.remarkData).then(res => {
            this.$message.success("添加备注成功");
            this.loadlist(this.paramsData);
            this.remarkBool = false
          })
        }
      })
    },
    /**
     * 查看轨迹
     */
    SeetracFun(row) {
      this.pointListD = true
      this.searchEvent = {current:1,size: 100,ids:row.eventIds}
      this.getEventList(1)
    },
    /**
     * 编辑新增关闭事件
     */
    CloseChangePlan() {
      this.DeviceData =  {
        dayNumber:1,frequency:1,eventName:''
      };
      this.addTimes = [];
      this.WeekSelectArray = [];
      this.MountSelectArray = [];
      this.$refs.DeviceDataAdd.resetFields()
    },
    /**
     * 新增任务
     */
    addGroup() {
      this.titles = '新增任务'
      this.addVisible = !this.addVisible;
    },
    /**
     * 配置详情
     */
    seeDetil(e) {
      this.detilVisible = !this.detilVisible;
      this.detiltable = e;
    },
    /**
     * 转派
     */
    handle(row) {
      this.titles = '转派'
      this.DeviceData = {...this.DeviceData,...row}
      // console.log(this.DeviceData)
      // if(row.taskDurationEndTime && row.taskDurationStartTime) {
      //   this.DeviceData.StartAndEndTime = [
      //     row.taskDurationEndTime,
      //     row.taskDurationStartTime,
      //   ];
      // }
      // 获取巡线员
      getUserList({userId:row.id,type:2}).then((e) => {
        this.excutorSpecialArray = e.data;
      });
      this.redeployVisible = !this.redeployVisible;
    },
    /**
     * 转派提交
     */
    submitRedeploy(){
        this.$refs.DeviceData.validate((valid) => {
        if (valid) {
          let data = {id:this.DeviceData.id,
          taskDurationEndTime:this.DeviceData.taskDurationEndTime,
          taskDurationStartTime:this.DeviceData.taskDurationStartTime,
          taskRemark:this.DeviceData.taskRemark,
          taskUser:this.DeviceData.taskUser}
          redeploy(data).then(res => {
            this.$message.success(res.msg);
            this.loadlist(this.paramsData);
            this.redeployVisible = false
          })
        }
      })
    },
    /**
     * 新增任务
     */
    submitPatrol() {
      let that = this
      this.$refs.DeviceDataAdd.validate((valid) => {
        if (valid) {
          if(Number(this.DeviceData.dayNumber) > 30||Number(this.DeviceData.dayNumber) < 0) {
            that.$message.error('请输入1-30的正整数')
            return
          }
          if(Number(this.DeviceData.frequency) > 30||Number(this.DeviceData.frequency) < 0) {
            that.$message.error('请输入1-30的正整数')
            return
          }
          // that.DeviceData.taskUser = that.DeviceData.taskUser.join(',')
          updatetask(that.DeviceData).then((res) => {
            that.addVisible = !that.addVisible;
            that.$message.success("操作成功");
            that.paramsData.current = 1;
            that.loadlist(that.paramsData);
          });
        }
      });
    },
    /**
     * 查询
     */
    search() {
      if (this.DataPicker&&this.DataPicker.length > 0) {
        this.paramsData.startTime = this.DataPicker[0];
        this.paramsData.endTime = this.DataPicker[1];
      }else{
        this.paramsData.startTime =null;
        this.paramsData.endTime = null;
      }
      this.paramsData.current = 1;
      this.loadlist(this.paramsData);
    },
    handleCurrentChange(val) {
      this.paramsData.current = val;
      this.loadlist(this.paramsData);
    },
    handleSizeChange(val) {
      this.paramsData.size = val;
      this.loadlist(this.paramsData);
    },
    handleCurrentChangeEvent(val) {
      this.searchEvent.current = val;
      this.getEventList();
    },
    handleSizeChangeEvent(val) {
      this.searchEvent.size = val;
      this.getEventList(1);
    },
  /**
   * 重置
   */
    resize() {
      this.paramsData = {
        current: 1,
        size: 100,
        taskType:1,
        searchText: "",
      };
      this.DataPicker = [];
      this.loadlist(this.paramsData);
    },
    /**
     * 复选
     */
    // handleSelectionChange(val) {
    //   this.multipleSelection = val;
    // },
    /**
     * 删除
     */
    del(row) {
      this.$confirm(`是否确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        let id = [];
        id.push(row.id);
        taskDelete({ ids: id }).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          // this.paramsData.current = 1;
          if(this.tableData.length == 1 && (this.paramsData.current != 1)) {
            this.paramsData.current -= 1 
          }
          this.loadlist(this.paramsData);
        });
      });
    },
    // MutiDeleteFun() {
    //   if (this.multipleSelection.length > 0) {
    //     this.$confirm(`是否批量删除?`, "提示", {
    //       confirmButtonText: "确定",
    //       cancelButtonText: "取消",
    //       type: "warning",
    //     }).then(() => {
    //       let arr = [];
    //       this.multipleSelection.forEach((item) => {
    //         arr.push(item.id);
    //       });
    //       deletetask({ ids: arr }).then((res) => {
    //         if (res.code === 200) {
    //           this.$message({
    //             message: "删除成功",
    //             type: "success",
    //           });
    //           this.paramsData.current = 1;
    //           this.loadlist(this.paramsData);
    //         } else {
    //           this.$message({
    //             message: "删除失败",
    //             type: "error",
    //           });
    //         }
    //       });
    //     });
    //   } else {
    //     this.$message.error("未选择删除项目");
    //   }
    // },
    /**
     * 获取列表
     */
    loadlist(obj) {
      taskPageEvent(obj).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
  },
  
};
</script>

<style scoped lang="less">
.point {
  width: 100%;
  
}

// 搜索栏样式
// .modalityTop{
    // border: 1px solid #ecedf1;
    // padding: 20px 0;
    // border-radius: 5px;
    // background: #ffffff;
    // margin-bottom: 12px;
  // 面包屑
  // .crumbs{
  //   padding: 10px 15px 20px;
  //   span{
  //     color: #3069E1;
  //   }
  // }
  // .el-form-item{
  //   margin-left: 20px;
  // }
  // .el-form-item__label{
  //   text-align: justify;
  // }
// }
// 搜索
.searchForm{
  display: flex;
  flex-direction: row;
   flex-wrap: wrap;
    // justify-content: space-between;
  .el-form-item{
    margin: 0;
  }
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  margin-top: 10px;
  // padding: 20px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
  }
//    ::v-deep.warning-row{
//     background: #F9FAFC;
//      .el-table__cell>.cell {
//     font-size: 14px;
//     color: #0C235B;
    
//      }
  }
// ::v-deep.success-row{ 
//   background: #ffffff;
//   .el-table__cell>.cell {
//     font-size: 14px;
//     color: #0C235B;
//   }
// }
// }
// ::v-deep.el-table th.el-table__cell>.cell{
//   font-size: 15px;
// }
.el-divider--horizontal{
  width: 100%;
  margin: 20px auto 0px;
}
.el-pagination{
        margin: 15px 0px 15px;
  }
  // ::v-deep.el-date-picker{
  //   top: 521px!important;
  //   left: 477px!important;
  // }
</style>
